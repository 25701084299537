.hero {
   display: flex;
   background-color: var(--section-bg);
   box-shadow: 0px 4.8rem 150px 6px rgba(242, 46, 98, 0.48);
   border-radius: var(--radius);
   overflow: hidden;
}

.hero--content {
   width: 100%;
}

.hero--content h1 {
   color: var(--main-red);
   letter-spacing: -0.03rem;
   font-weight: bold;
}

.hero--content h6 {
   color: var(--global-font);
}

.hero--button {
   background-color: var(--field-bg);
   border: 2px solid var(--main-red);
   color: inherit;
   height: 3.6rem;
   border-radius: var(--radius);
   box-shadow: 0px 0px 250px 1px rgba(242, 46, 98, 0.51);
   color: var(--main-red) !important;
   text-decoration: none;
}

.hero--subcontainer {
   width: 42%;
   display: flex;
   justify-content: flex-end;
   box-shadow: 12px 0px 30px 15px #132233 inset;
}

.hero--subcontainer img {
   width: 30rem;
   height: 28.2rem;
   object-fit: cover;
}
