div > input {
   width: 4.5rem;
   margin-left: 1.2rem;
   border-radius: 99999px;
   padding: 0 0.6rem;
   border: none;
   margin-top: 0.42rem;
   height: 1.8rem;
   outline: none;
   color: gray;
   font-weight: 300;
}

input[type="checkbox"] {
   height: 0.87rem;
   font-size: initial;
}

.caption {
   font-size: 0.99rem;
   color: var(--global-font-o);
}
