html {
   --radius: 0.75rem;
   --background-color: #111026;
   --main-red: #de2c6e;
   --global-font: #fffcfd;
   --global-font-o: rgba(255, 252, 253, 0.6);
   --field-bg: #4c2540;
   --section-bg: #132233;
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: #111026 !important;
   color: #fffcfd !important;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

a {
   text-decoration: none;
}
