.s-step--select {
   background-color: var(--global-font-o);
}

.s-step--button {
   background-color: var(--field-bg);
   border: 2px solid var(--main-red);
   color: inherit;
   height: 3.6rem;
   border-radius: var(--radius);
   box-shadow: 0px 0px 250px 1px rgba(242, 46, 98, 0.3);
   color: var(--main-red) !important;
   text-decoration: none;
   cursor: pointer;
}

@media screen and (min-width: 769px) {
   .secondGrid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
   }
}
