button {
   border: none;
}

a {
   text-decoration: none !important;
}

.red-text {
   color: var(--main-red);
}
