#fileInput {
   position: relative;
   opacity: 0;
   z-index: 2;
   width: 100%;
   height: 0;
   cursor: pointer;
}

.upload--content {
   margin-top: 6rem;
}

.upload--img {
   width: 13.8rem;
}

label {
   color: var(--main-red);
}

.choose--button {
   width: 50%;
}

.upload--button {
   background-color: var(--main-red);
   color: inherit;
   height: 3.6rem;
   border-radius: var(--radius);
   box-shadow: 0px 0px 250px 1px rgba(242, 46, 98, 0.3);
   color: var(--global-font) !important;
   text-decoration: none;
   cursor: pointer;
}

.__submit {
   background-color: var(--field-bg);
   color: var(--main-red) !important;
   border: 2px solid var(--main-red);
   width: 50%;
}

progress {
   background-color: var(--global-font);
   border-radius: 50%;
   height: 1.2rem;
   opacity: 0.6;
}

progress::-webkit-progress-value {
   background-color: #21de9a;
}

progress::-moz-progress-bar {
   background-color: #21de9a;
}

.upload--progress {
   height: 0.9rem;
}

@media screen and (min-width: 769px) {
   .choose--button {
      width: 25%;
   }

   .__submit {
      width: 25%;
   }
}
