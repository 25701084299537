.demo--container {
   background-color: var(--background-color);
   /* border: 3px solid red; */
   min-height: 81vh;
   border-radius: var(--radius);
   border-top-left-radius: 0;
   border-top-right-radius: 0;
   box-shadow: 0px 5.4rem 180px 24px rgba(242, 46, 98, 0.48);
}

hr {
   color: var(--background-color) !important;
   opacity: 1;
   /* border: 3px !important; */
   /* border: 12px; */
}

.demo--btn {
   justify-content: flex-end;
   background-color: var(--main-red);
   /* border: 1px solid var(--global-font-o); */
   color: var(--global-font) !important;
   height: 3.6rem;
   border-radius: var(--radius);
   box-shadow: 0px 0px 250px 1px rgba(242, 46, 98, 0.3);
   text-decoration: none;
   cursor: pointer;
}

.second-step-container {
   inset: 0;
}
