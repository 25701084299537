.download--content {
   margin-top: 6rem;
}

.download--btn {
   border: 2px solid var(--main-red);
   color: inherit;
   height: 3.6rem;
   border-radius: var(--radius);
   box-shadow: 0px 0px 250px 1px rgba(242, 46, 98, 0.3);
   text-decoration: none;
   cursor: pointer;
   background-color: var(--main-red);
   color: var(--global-font) !important;
   width: 75%;
}

@media screen and (min-width: 769px) {
   .download--btn {
      width: 25%;
   }
}
